import {useRef, useState} from 'react';
// import Clipboard from '@react-native-clipboard/clipboard';
const imgOjoAbierto = require('./icon-eye.svg');
const imgOjoCerrado = require('./icon-eye-slash.svg');
const imgCopy = require('./icon-copy.svg');

export const useInputText = (
  name,
  value,
  type,
  requiredField,
  keyboardTypePassword,
  secureTextEntry,
  validateInput,
) => {
  const TOP_FOCUS = '-8px';
  const TOP_DEFAULT = '23px';
  const [ojo, setOjo] = useState(imgOjoAbierto);
  const [iconCopy, setIconCopy] = useState(imgCopy);
  const [secure, setSecure] = useState(secureTextEntry);
  const [focus, setFocus] = useState(false);
  //const [validateInput] = useState<boolean>(valInput);
  const [error, setError] = useState(false);
  const [showError, setShowError] = useState(false);

  let top = 0;
  const topFocus = '-11px';
  const topBlur = '15px';

  const showPass = () => {
    setSecure(!secure);
    //console.log('showPass => ', secure);
    secure ? setOjo(imgOjoCerrado) : setOjo(imgOjoAbierto);
  };
  const onCopyValue = () => {
    // Clipboard.setString(value);
  };

  const onFocus = () => {
    console.log('onFocus => ', name);
    top = topFocus;
    setFocus(true);
    moveLabel(true);
  };

  const onBlur = () => {
    console.log('onBlur => ', name);
    top = topBlur
    setFocus(false);
    if (!value) {
      moveLabel(false);
    }
    if (validateInput) {
      onValidateInput();
    }
  };

  const moveLabel = (f) => {
    // console.log('moveLabel ');
    const newTop = f || value ? TOP_FOCUS : TOP_DEFAULT;

    //setTop(paddingTop);
  };

  const onParentValidate = () => {
    // console.log('onParentValidate');
    onValidateInput();
  };

  const onValidateInput = () => {
    let ERR;

    if(requiredField === true){
      console.log(`onValidateInput => ${name} => value =>`, value);
      if (value === '' || value === undefined) {
        setError('El campo no puede estar vacío');
        setShowError(true);
        return;
      } else {
        setShowError(false);
      }
    } else {
      setShowError(false);
    }

    switch (type) {
      case 'text':
        if(requiredField === true) {
          if ( keyboardTypePassword ) {
            if ( value.length < 6 ) {
              setError('Deben ser al menos 6 caracteres');
              setShowError(true);
            } else {
              setShowError(false);
            }
          }
        } else {
          setShowError(false);
        }
        break;
      case 'number':
        if(requiredField === true) {
          value = Number(value);
          if ( isNaN(value) ) {
            setError('Formato numérico incorrecto');
            setShowError(true);
          } else {
            setShowError(false);
          }
        } else {
          setShowError(false);
        }
        //console.log(`numeric => ${typeof value}`);
        break;
      case 'email':
        //console.log(`email-address => ${typeof value}`);
        if(requiredField === true) {
          ERR = !validateEmail(value);
          setError('Formato de correo incorrecto');
          setShowError(ERR);
        } else {
          setShowError(false);
        }
        break;
      case 'tel':
        console.log('llega aqui')
        if(requiredField === true) {
          if ( value.length < 10 ) {
            setError('Formato de teléfono incorrecto');
            setShowError(true);
          } else {
            setShowError(false);
          }
        } else {
          setShowError(false);
        }
        //console.log(`phone-pad => ${typeof value}`);
        break;
      case 'creditcard':
        if (requiredField) {
          // Aquí puedes implementar la validación específica para el tipo de tarjeta de crédito
          // Por ejemplo, puedes usar una librería como 'card-validator' para verificar el número de tarjeta de crédito
          // y su fecha de expiración
          // https://www.npmjs.com/package/card-validator
          // Por simplicidad, aquí asumiré que debes ingresar 16 dígitos para el número de tarjeta de crédito
          if (!/^\d{16}$/.test(value)) {
            setError('El número de tarjeta de crédito debe tener 16 dígitos.');
            setShowError(true);
          } else {
            setShowError(false);
          }
        } else {
          setShowError(false);
        }
      break;
    case 'expiration':
      if (requiredField) {
        // Aquí puedes implementar la validación para la fecha de expiración de la tarjeta de crédito
        // Por ejemplo, puedes verificar si la fecha está en el formato MM/YY y si es una fecha válida
        if (!/^((0[1-9])|(1[0-2]))\/\d{2}$/.test(value)) {
          setError('Formato de fecha de expiración incorrecto. Debe ser en formato MM/YY.');
          setShowError(true);
        } else {
          setShowError(false);
        }
      } else {
        setShowError(false);
      }
      break;
    case 'cvv':
      if (requiredField) {
        // Aquí puedes implementar la validación para el CVV de la tarjeta de crédito
        // Por ejemplo, puedes verificar si el CVV tiene 3 o 4 dígitos
        if (!/^\d{3,4}$/.test(value)) {
          setError('El CVV debe tener 3 o 4 dígitos.');
          setShowError(true);
        } else {
          setShowError(false);
        }
      } else {
        setShowError(false);
      }
      break;
    }

    //console.log(`onValidate => ${type} - ${value}`);
  };

  const validateEmail = (email) => {
    const re =
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  };

  return {
    top,
    topFocus,
    topBlur,
    ojo,
    iconCopy,
    secure,
    type,
    focus,
    error,
    showError,
    showPass,
    onCopyValue,
    onFocus,
    onBlur,
    onParentValidate,
  };
};
