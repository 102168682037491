import React, {useEffect} from 'react';
import {
  ContIconCopy,
  ContInput,
  ContInputText,
  Label,
  ErrorText,
  InputText,
  Text,
  InputEye, EyeFocus,
} from './stylesInputText';
import {useInputText} from './useInputText';
import {GlobalColors, GlobalColorsInputText} from '../../../styles/appColors';
import {GlobalBackgroundColors} from '../../../styles/GlobalStyles';

export const InputTextMyS = ({
  name,
  label,
  value,
  type,
  requiredField = false,
  parentValidate= false,
  keyboardTypePassword = false,
  onChange,
  onBlurParent = null,
  secureTextEntry,
  maxLength,
  validateInput = true,
  statusInput,
  height = '56px',
  backgroundColor = GlobalColorsInputText.primaryBg,
  borderColor = GlobalColorsInputText.labelFocus,
  fontSize = '16px',
  fontColor = GlobalColors.text,
  paddingHorizontal = '12px',
  textAlign = '',
  showLabel = true,
  labelColor = GlobalColorsInputText.labelFocus,
  autoFocus = false,
  forceFocus = false,
  readOnly = true,
  copyValue = false,
  ref = null,
  showError = false,
  error,
}) => {
  const {
    showPass,
    onCopyValue,
    onFocus,
    onBlur,
    onParentValidate,
    top,
    topFocus,
    topBlur,
    ojo,
    iconCopy,
    secure,
    focus,
  } = useInputText(
    name,
    value,
    type,
    requiredField,
    keyboardTypePassword,
    secureTextEntry,
    validateInput,
    statusInput,
  );

  const onBlurInner = () => {
    // console.log('onBlurInner => onBlurParent => ', onBlurParent);
    onBlur();
    if (onBlurParent !== null) {
      onBlurParent();
    }
  };

  useEffect(() => {
    // console.log('InputTextMyS -> value ->', value);
    if (forceFocus) {
      onFocus();
    }
  }, []);

  useEffect(() => {
    // console.log(`parentValidate => ${name} =>`, parentValidate);
    if (parentValidate) {
      onParentValidate();
    }
  }, [parentValidate]);

  const handleChange = (e) => {
    let inputValue = e.target.value;
    // Validar longitud máxima de caracteres para campos específicos
    if (type === 'creditcard') {
      inputValue = inputValue.slice(0, 16); // Limitar a 16 caracteres para números de tarjeta de crédito
    } else if (type === 'expiration') {
      // Insertar "/" automáticamente después de 2 caracteres
      if (inputValue.length === 2 && value.length < inputValue.length) {
        inputValue += '/';
      }
      inputValue = inputValue.replace(/[^0-9/]/g, ''); // Permitir solo números y "/"
    } else if (type === 'cvv') {
      inputValue = inputValue.slice(0, 4); // Limitar a 4 caracteres para CVV
      inputValue = inputValue.replace(/\D/g, ''); // Permitir solo dígitos
    } else if (type === 'tel') {
      inputValue = inputValue.slice(0, 10); // Limitar a 10 caracteres para CVV
      inputValue = inputValue.replace(/\D/g, ''); // Permitir solo dígitos
    }
    // Llamar a la función onChange con el valor modificado
    onChange(inputValue);
  };

  return (
    <ContInputText
      backgroundColor={backgroundColor}
      borderColor={borderColor}
      height={height}
      marginBottom={showError ? '25px' : '16px'}
      paddingHorizontal={paddingHorizontal}>
      {showLabel && (
        // <Label translateY={focus ? top : '50px'}>
        <Label
          id={'labelInput'}
          bg={focus || value ? GlobalBackgroundColors.bgPrimary : 'transparent'}
          translateY={focus || value ? topFocus : topBlur}
          onClick={() => {
            document.getElementById(name).focus();
          }}
        >
          <Text
            style={{padding: 0}}
            color={focus || value ? labelColor : GlobalColorsInputText.label}
          >
            {' '}{label}{' '}
          </Text>
        </Label>
      )}
      <ContInput>
        <InputText
          id={name}
          name={name}
          fontSize={fontSize}
          color={fontColor}
          textAlign={textAlign ? textAlign : 'left'}
          value={value}
          type={type}
          // secureTextEntry={secure}
          onChange={(e) => handleChange(e)}
          onFocus={() => onFocus()}
          // onBlur={() => onBlur()}
          onBlur={() => onBlurInner()}
          maxLength={maxLength}
          autoFocus={autoFocus}
          readOnly={readOnly}
          ref={ref}
        />
        {secureTextEntry ? (
          <InputEye
            /*style={
              focus || value ? styles.containerEyeFocus : styles.containerEye
            }*/
            onPress={() => showPass()}>
            <img src={ojo} alt={''}/>
          </InputEye>
        ) : null}
        {copyValue ? (
          <ContIconCopy
            onPress={() => onCopyValue()}>
            <img src={iconCopy} alt={''}/>
          </ContIconCopy>
        ) : null}
      </ContInput>
      {showError ? <ErrorText>{error}</ErrorText> : null}
    </ContInputText>
  );
};
