import styled, {createGlobalStyle} from 'styled-components';
import {GlobalColorsInputText} from '../../../styles/appColors';
import {GlobalFonts} from '../../../styles/GlobalStyles';

export const GlobalStyleInputText = createGlobalStyle`
  .PhoneInput{
    height: 52px;
  }
  .PhoneInputInput{
    height: 100% !important;
    border: none;
  }
`

export const ContInputText = styled.div`
  width: 100%;
  height: ${props => props.height};
  margin-bottom: ${props => props.marginBottom};
  padding: 0px ${props => props.paddingHorizontal};
  /*background-color: ${GlobalColorsInputText.primaryBg};*/
  background-color: ${props => props.backgroundColor};
  border: 2px solid ${props => props.borderColor};
  border-radius: 10px;
`
export const Label = styled.div`
  position: absolute;
  background-color: ${props => props.bg};
  padding: 0px 5px;
  transform: translateY(${props => props.translateY});
  transition: transform 0.3s ease-in-out;
`
export const LabelPhone = styled.div`
  position: absolute;
  background-color: ${props => props.bg};
  padding: 0 ${props => props.left};
  transform: translateY(${props => props.translateY});
`
export const Text = styled.div`
  font: 16px ${GlobalFonts.fontPrimary};
  text-align: left;
  color: ${GlobalColorsInputText.label};
`
export const ContInput = styled.div`
  width: 100%;
`
export const InputText = styled.input`
  //position: absolute;
  width: 100%;
  height: 54px;
  background-color: transparent !important;
  border: none !important;
  font: ${props => props.fontSize} ${GlobalFonts.fontPrimary};
  text-align: ${props => props.textAlign};
  color: ${GlobalColorsInputText.color};
`
export const InputEye = styled.div`
  position: absolute;
  width: 30px;
  height: 30px;
  top: 12px;
  right: 0;
`
export const EyeFocus = styled.div`
  position: absolute;
  width: 30px;
  height: 30px;
  top: 12px;
  right: 0;
`
export const ContIconCopy = styled.div`
  position: absolute;
  width: 30px;
  height: 30px;
  top: 20px;
  right: 0;
`
export const ErrorText = styled.div`
  position: relative;
  bottom: 0px;
  margin-left: 4px;
  font: 12px ${GlobalFonts.fontPrimary};
  color: ${GlobalColorsInputText.errorText};
`

