export const GlobalColors = {
  primary: '#FC6121',
  bgHeader: '#FC6121',
  bgBody: '#F8F8F8',
  bgContainers: '#EBEBEB',
  bgModal: '#72727280',
  title: '#FC6121',
  titleSecond: '#4F4F4F',
  text: '#727272',
  separator: '#727272',
  highlightedText: '#FE7F52',
  grayDark: '#505050',
  grayDark100: '#4F4F4F',
  grayDark60: '#959595',
  grayLight: '#DCDCDC',
  success: '#29BB89',
  failed: '#EC4646',
  headerText: '#FFFFFF',
  disabled: '#DCDCDC',
};
export const GlobalColorsButton = {
  primaryBg: '#FE7F52',
  primaryText: '#FFFFFF',
  primaryBorder: '#FE7F52',
  disablePrimaryBg: '#F1F1F1',
  disablePrimaryText: '#959595',
  disablePrimaryBorder: '#B9B9B9',
  secondaryBg: '#FFFFFF',
  secondaryText: '#FC6121',
  secondaryBorder: '#FE7F52',
  disableSecondaryBg: '#F1F1F1',
  disableSecondaryText: '#959595',
  disableBorderSecondary: '#B9B9B9',
  tertiaryBg: 'transparent',
  tertiaryText: '#FC6121',
  tertiaryBorder: 'transparent',
  disableTertiaryBg: '#F1F1F1',
  disableTertiaryText: '#959595',
  disableBorderTertiary: 'transparent',
};
export const GlobalColorsInputText = {
  primaryBg: '#FFFFFF',
  primaryColor: '#4F4F4F',
  primaryBorder: '#E4E4E4',

  disableBg: '#FFFFFF',
  disableColor: '#4F4F4F',
  disableBorder: '#E4E4E4',

  statusBorderError: '#EC4646',
  statusBorderSuccess: '#29BB89',
  statusBorderWarning: '#FFC947',

  color: '#4F4F4F',
  label: '#4F4F4F',
  labelFocus: '#5B52BC',
  errorText: '#EC4646',
};
export const GlobalColorsNotifications = {
  successBg: '#29BB89',
  successColor: '#FFFFFF',
  successBorder: '#1E8763',

  errorBg: '#F06B6B',
  errorColor: '#FFFFFF',
  errorBorder: '#EC4646',
};

export const GlobalColorsMenu = {
  bgPrimary: '#FD814D',
  border: '#FFFFFF',
  textPrimary: '#FFFFFF',
};
